import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import './message-center.scss';
import { DateTime } from 'luxon';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import * as QueryString from 'query-string';
import { Loader } from 'concert-ui-library';
import { useSelector } from 'react-redux';
import { Conversation, useGetConversationsQuery } from '../../services/graphql/generated';
import { NotificationContext } from '../../notification-context';
import { ROUTES, ROUTE_PARAMS } from '../../constants';
import {
    EPISODE_INACTIVE_WARNING_MESSAGE,
    PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE,
    SEND_SECURE_MESSAGES_CUSTOM_PERMISSION,
} from './constants';
import { selectUser } from '../user/selector';
import { User } from '../user/slice';
import { ConversationContext } from './message-sender';

export const MessageCenter: FunctionComponent = () => {
    const currentLocation = useLocation();
    const history = useHistory();
    const notificationContext = useContext(NotificationContext);
    const user: User | null = useSelector(selectUser);
    const { episodeId } = QueryString.parse(currentLocation.search);
    const { state } = currentLocation;
    const {
        data: conversation,
        error: conversationError,
        isFetching,
        refetch: refetchConversations,
    } = useGetConversationsQuery({
        request: {
            episodeId: episodeId as string,
            userId: '',
        },
    });
    const cardClassName = (rowData: Conversation) => {
        return rowData.hasUnopenedMessages ? 'unopened-messages-conversation' : '';
    };
    const dateTemplate = (rowData: string, patientTz: string) => {
        const fileDateTime = DateTime.fromISO(rowData, { zone: patientTz });
        return fileDateTime.toFormat('LLL dd, yyyy, hh:mm a ZZZZ');
    };
    const handleRowClick = (conv: Conversation) => {
        const conversationContext: ConversationContext = {
            episodeId: conv.episodeId,
            clinicianId: conv.clinicianId,
            episodeName: conv.episodeName,
            patientName: conv.patientName,
            patientId: conv.patientId,
            topic: conv.topic,
        };
        history.push({
            pathname: ROUTES.CONVERSATION,
            search: `?${ROUTE_PARAMS.EPISODE_ID}=${episodeId}&${ROUTE_PARAMS.CONVERSATION_ID}=${conv.id}`,
            state: conversationContext,
        });
    };
    const canSendMessage = () => {
        const conversations = conversation?.getConversations;
        return (
            conversations?.patientCommunicationInformation?.canReceiveCommunications === true &&
            conversations?.episodeOwnerId === user?.id &&
            user?.sfCustomPermissions?.includes(SEND_SECURE_MESSAGES_CUSTOM_PERMISSION)
        );
    };

    const getEpisodeName = (): string => conversation?.getConversations?.episodeName ?? '';

    const getConversationContext = () => {
        return {
            episodeId,
            episodeName: getEpisodeName(),
            patientId: conversation?.getConversations?.patientId,
        };
    };

    const placeholderErrorMessage = 'Something Went wrong while fetching the conversations.';
    const requestedDataInvalidation = () =>
        state !== null && typeof state === 'object' && 'refresh' in state && state.refresh === true;

    const getNavigationModel = (): MenuItem[] => {
        return [
            {
                id: 'patient',
                label: getEpisodeName(),
                className: 'unclickable active',
            },
        ];
    };
    const getHomeItem = (): MenuItem => ({
        id: 'home',
        label: 'Messaging',
        className: 'unclickable',
    });

    useEffect(() => {
        if (conversationError) {
            notificationContext?.showError(placeholderErrorMessage);
        }
    }, [conversationError]);
    useEffect(() => {
        if (requestedDataInvalidation()) {
            refetchConversations();
        }
    }, []);
    useEffect(() => {
        const communicationInfo = conversation?.getConversations?.patientCommunicationInformation;
        if (communicationInfo && communicationInfo.canReceiveCommunications === false) {
            notificationContext?.showWarning(
                communicationInfo.hasActiveEpisode
                    ? PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE
                    : EPISODE_INACTIVE_WARNING_MESSAGE,
            );
        }
    }, [conversation]);
    const conversationCardsRender = (): JSX.Element[] | undefined => {
        const conversations = (conversation?.getConversations?.conversations ?? []) as Conversation[];
        return conversations.map((c: Conversation) => (
            <Card
                data-testid="message-card"
                className={`message-card ${cardClassName(c)}`}
                onClick={() => handleRowClick(c)}
                key={c.id}
                title={`${c.topic}`}
            >
                <p>{dateTemplate(c.lastActivityDate, c.patientTimezone)}</p>
            </Card>
        ));
    };

    return (
        <div className="app-container message-center-container">
            <BreadCrumb model={getNavigationModel()} home={getHomeItem()} />
            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <div className="new-conversation-btn">
                        <Button
                            icon="pi pi-plus"
                            className="p-button p-component p-button-primary"
                            data-testid="new-row"
                            disabled={!canSendMessage()}
                            onClick={() =>
                                history.push({
                                    pathname: ROUTES.CONVERSATION,
                                    search: `?${ROUTE_PARAMS.EPISODE_ID}=${episodeId}`,
                                    state: getConversationContext(),
                                })
                            }
                        />
                    </div>
                    {conversationCardsRender()}
                </>
            )}
        </div>
    );
};
