import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { ErrorResponse, QueryReturnValue } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { getGraphQLConfig, GraphQLSettings } from '../../config';
import { showSessionEndedDialog } from '../../containers/auth/slice';
import { isDevelopment } from '../../util';
import { ApiException, AppSyncAuthorizationHeaders, DEFAULT_GRAPHQL_URL, TOKEN_EXPIRED_MESSAGE } from './constants';

const graphQLConfig: GraphQLSettings = getGraphQLConfig();
export const isSessionExpiredError = (message?: string): boolean => !!message?.startsWith(TOKEN_EXPIRED_MESSAGE);
export const isUnauthorizedException = (message?: string): boolean =>
    message?.includes(ApiException.Unauthorized) ?? false;

const baseGraphQLQuery = graphqlRequestBaseQuery({
    url: graphQLConfig?.graphQLUrl ?? DEFAULT_GRAPHQL_URL,
    prepareHeaders: (headers, api: any) => {
        const { auth } = api.getState();
        const { token } = auth;

        if (isDevelopment()) {
            headers.set(AppSyncAuthorizationHeaders.Authorization, `${token}`);
            headers.set(AppSyncAuthorizationHeaders.ApiKey, `${graphQLConfig.apiKey}`);
        } else if (token) headers.set(AppSyncAuthorizationHeaders.Authorization, `${token}`);

        return headers;
    },
});

export const api = createApi({
    reducerPath: 'graphQLApi',
    baseQuery: async (args, api: any, extraOptions): Promise<any> => {
        const result = await baseGraphQLQuery(args, api, extraOptions);

        const isSessionExpired = isSessionExpiredError(result?.error?.message);
        const isUnauthorized = isUnauthorizedException(result?.error?.message);
        const isHandledError = isSessionExpired || isUnauthorized;
        if (isHandledError) {
            result.error = undefined;
            await api.dispatch(showSessionEndedDialog());
        }
        return result;
    },
    keepUnusedDataFor: 0,
    endpoints: () => ({}),
});
