import './session-ended-dialog.scss';

import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { selectIsSessionEndedDialogVisible } from '../containers/auth/selector';
import { hideSessionEndedDialog, setLoginRedirectLocation, unAuthenticate } from '../containers/auth/slice';

export const SessionEndedDialog: FunctionComponent = () => {
    const dispatch = useDispatch();

    const currentLocation = useLocation();

    const isSessionEndedDialogVisible: boolean = useSelector(selectIsSessionEndedDialogVisible);

    const hideDialog = () => {
        if (!isSessionEndedDialogVisible) return;
        dispatch(hideSessionEndedDialog());
    };
    const doLogin = () => {
        dispatch(
            setLoginRedirectLocation({
                location: {
                    pathname: currentLocation.pathname,
                    search: currentLocation.search,
                },
            }),
        );
        dispatch(unAuthenticate());
        dispatch(hideSessionEndedDialog());
    };

    const sessionEndedDialogFooter = (props: DialogProps) => {
        return (
            <>
                <Button onClick={hideDialog}>🚧</Button>
                <Button onClick={doLogin}>Log In</Button>
            </>
        );
    };

    return (
        <Dialog
            header="Your session has timed out"
            closable={false}
            draggable={false}
            footer={sessionEndedDialogFooter}
            className="session-ended"
            visible={isSessionEndedDialogVisible}
            onHide={hideDialog}
        >
            <p className="m-0">
                Any unsaved work will be lost by clicking &quot;Log In&quot;. To preserve any unsaved work, click 🚧 to
                return to the page and copy your entry.
            </p>
        </Dialog>
    );
};
