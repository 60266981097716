import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SetLoginRedirectLocation = {
    pathname?: string | null;
    search?: string | null;
};
export type SetLoginRedirectLocationAction = {
    location: {
        pathname: string;
        search: string;
    };
};

export const initialState = {
    auth: false,
    token: '',
    showSessionEndedDialog: false,
    loginRedirectLocation: null as SetLoginRedirectLocation | null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeAuthState(state, action) {
            state.auth = action.payload;
            state.token = action.payload.token;
        },
        unAuthenticate(state) {
            state.auth = false;
            state.token = '';
        },
        unAuthenticateUserInitiate(state) {
            state.auth = false;
            state.token = '';
        },
        setLoginRedirectLocation(state, action: PayloadAction<SetLoginRedirectLocationAction>) {
            state.loginRedirectLocation = action.payload.location;
        },
        showSessionEndedDialog(state) {
            state.showSessionEndedDialog = true;
        },
        hideSessionEndedDialog(state) {
            state.showSessionEndedDialog = false;
        },
    },
});

export const {
    changeAuthState,
    setLoginRedirectLocation,
    unAuthenticate,
    unAuthenticateUserInitiate,
    showSessionEndedDialog,
    hideSessionEndedDialog,
} = authSlice.actions;

export const { reducer } = authSlice;
